* {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
	font-family: 'Open Sans', sans-serif;
}

html,
body,
#root,
.App {
	min-height: 100vh;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
