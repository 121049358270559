.react-datetime-picker {
	display: inline-flex;
	position: relative;
}
.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-datetime-picker--disabled {
	background-color: #f0f0f0;
	color: #6d6d6d;
}

/* this is for the border */
.react-datetime-picker__border-dark .react-datetime-picker__wrapper {
	border: thin solid white;
	width: 75%;
}

.react-datetime-picker__border-light .react-datetime-picker__wrapper {
	border: thin solid #00334e;
}

.react-datetime-picker__wrapper {
	border-radius: 5px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	padding: 2px;
	border: thin solid gray;
}
.react-datetime-picker__inputGroup {
	min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
	flex-grow: 1;
	padding: 0 2px;
}
.react-datetime-picker__inputGroup__divider {
	padding: 0 2px;
	font-size: 1.5em;
	white-space: pre;
}

/* input color set here */
.react-datetime-picker__inputGroup__input--dark input.react-datetime-picker__inputGroup__input {
	color: white;
	font-size: 1.5em;
}

.react-datetime-picker__inputGroup__input--light input.react-datetime-picker__inputGroup__input {
	color: #00334e;
	font-size: 1.5em;
}

/* and the am/pm selector */

.react-datetime-picker__inputGroup__amPm--dark select.react-datetime-picker__inputGroup__amPm {
	color: white;
	font-size: 1.5em;
}

.react-datetime-picker__inputGroup__amPm--light select.react-datetime-picker__inputGroup__amPm {
	color: #00334e;
	font-size: 1.5em;
}

.react-datetime-picker__inputGroup__input {
	min-width: 0.54em;
	height: calc(100% - 2px);
	position: relative;
	padding: 1px;
	border: 0;
	background: none;
	font: inherit;
	box-sizing: content-box;
	-moz-appearance: textfield;
}
.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.react-datetime-picker__inputGroup__input:invalid {
	background: rgba(255, 0, 0, 0.1);
}
.react-datetime-picker__inputGroup__input--hasLeadingZero {
	margin-left: -0.54em;
	padding-left: calc(1px + 0.54em);
}
.react-datetime-picker__inputGroup__amPm {
	font: inherit;
	-moz-appearance: menulist;
}
.react-datetime-picker__button {
	border: 0;
	background: transparent;
	padding: 4px 6px;
}
.react-datetime-picker__button:enabled {
	cursor: pointer;
}
.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
	stroke: #0078d7;
}
.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
	stroke: #6d6d6d;
}

/* buttons */

.buttons--dark .react-datetime-picker__button svg {
	stroke: white;
}

.buttons--light .react-datetime-picker__button svg {
	stroke: #00334e;
}

.buttons--dark:hover .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
	stroke: #ff5260;
}

.buttons--light:hover .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
	stroke: #ee6f57;
}

.react-datetime-picker__button svg {
	display: inherit;
}
.react-datetime-picker__calendar,
.react-datetime-picker__clock {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
}
.react-datetime-picker__calendar--closed,
.react-datetime-picker__clock--closed {
	display: none;
}
.react-datetime-picker__calendar {
	width: 350px;
	max-width: 100vw;
}
.react-datetime-picker__calendar .react-calendar {
	border-width: thin;
}
.react-datetime-picker__clock {
	width: 200px;
	height: 200px;
	max-width: 100vw;
	padding: 25px;
	background-color: white;
	border: thin solid #a0a096;
}
