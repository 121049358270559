/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
	box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
	font-size: 18px;
	line-height: 24px;
	font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
	position: relative;
	background-color: #fff;
	width: 100%;
	min-width: 240px;
	z-index: 1;
	border-radius: 4px;
	transition: width .25s, min-width .25s;
	@media (max-width: 30em) {
		font-size: 14px;
		line-height: 20px;
	}
}

.mapboxgl-ctrl-geocoder--input {
	font: inherit;
	width: 100%;
	border: 0;
	background-color: transparent;
	margin: 0;
	height: 50px;
	color: #404040; /* fallback */
	color: rgba(0, 0, 0, 0.75);
	padding: 6px 45px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
	display: none; /* hide input clear button in IE */
}

.mapboxgl-ctrl-geocoder--input:focus {
	color: #404040; /* fallback */
	color: rgba(0, 0, 0, 0.75);
	outline: 0;
	box-shadow: none;
	outline: thin dotted;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
	z-index: 2;
	position: absolute;
	right: 8px;
	top: 7px;
	display: none;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions {
	box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
}

/* Collapsed */
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
	width: 50px;
	min-width: 50px;
	transition: width .25s, min-width .25s;
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
	background-color: #fff;
	border-radius: 4px;
	left: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	width: 100%;
	top: 110%; /* fallback */
	top: calc(100% + 6px);
	z-index: 1000;
	overflow: hidden;
	font-size: 15px;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
	top: auto;
	bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
	cursor: default;
	display: block;
	padding: 6px 12px;
	color: #404040;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
	color: #404040;
	background-color: #f3f3f3;
	text-decoration: none;
	cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
	font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
	display: inline-block;
	vertical-align: middle;
	speak: none;
	fill: #757575;
	top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
	position: absolute;
	top: 13px;
	left: 12px;
	width: 23px;
	height: 23px;
}

.mapboxgl-ctrl-geocoder--button {
	padding: 0;
	margin: 0;
	border: none;
	cursor: pointer;
	background: #fff;
	line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
	width: 20px;
	height: 20px;
	margin-top: 8px;
	margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
	fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-loading {
	width: 26px;
	height: 26px;
	margin-top: 5px;
	margin-right: 0px;
	-moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
	-webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
	animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

/* Animation */
@-webkit-keyframes rotate {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotate {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* Media queries*/
@media screen and (min-width: 640px) {
	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
		width: 36px;
		min-width: 36px;
	}

	.mapboxgl-ctrl-geocoder {
		width: 33.3333%;
		font-size: 15px;
		line-height: 20px;
		max-width: 360px;
	}
	.mapboxgl-ctrl-geocoder .suggestions {
		font-size: 13px;
	}

	.mapboxgl-ctrl-geocoder--icon {
		top: 8px;
	}

	.mapboxgl-ctrl-geocoder--icon-close {
		width: 16px;
		height: 16px;
		margin-top: 3px;
		margin-right: 0;
	}

	.mapboxgl-ctrl-geocoder--icon-search {
		left: 7px;
		width: 20px;
		height: 20px;
	}

	.mapboxgl-ctrl-geocoder--input {
		height: 36px;
		padding: 6px 35px;
	}

	.mapboxgl-ctrl-geocoder--icon-loading {
		width: 26px;
		height: 26px;
		margin-top: -2px;
		margin-right: -5px;
	}

	.mapbox-gl-geocoder--error {
		color: #909090;
		padding: 6px 12px;
		font-size: 16px;
		text-align: center;
	}
}
