.themedCalLink {
	color: inherit;
	text-decoration: none !important;
}

.outlook-cal-dark {
	color: #ff5260;
	text-decoration: none !important;
}

.outlook-cal-light {
	color: #ff5260;
	text-decoration: none !important;
}

/* table cell syles */

.tdDark {
	background: transparent;
	border: none;
	color: white;
	font-size: 1.4em;
}

.tdTodayDark {
	background: #03c4a1 !important;
	margin: 5px;
}

.tdTodayLight {
	background: #00334e !important;
	color: white !important;
}

.tdLight {
	background: transparent;
	border: none;
	color: #00334e;
	font-size: 1.4em;
}

.fc-button-primary {
	background: #00334e !important;
}

.fc .fc-list-sticky .fc-list-day > * {
	background: none !important;
}
